<template>
    <v-card>
        <v-card v-if="addressData">
            <v-card-text>
                <psi-form-section
                    two-cols
                    :value="addressData"
                    name="profile-address-data"
                    :label="['Profile', 'Address']"
                    :fields="mergedFields(profileFields, addressFields)"
                    :color="color"
                    @input="update"
                ></psi-form-section>
                <psi-form-section
                    two-cols
                    :value="addressData"
                    name="additional-data"
                    label="Additional Information"
                    :fields="groupedFields(metaFields)"
                    :color="color"
                    @input="update"
                ></psi-form-section>
            </v-card-text>
            <v-card-actions class="mr-2">
                <v-spacer></v-spacer>
                <v-btn
                    color="grey lighten-2"
                    class="mr-2"
                    @click.stop="$emit('close')"
                >
                    <v-icon class="mr-1">mdi-close</v-icon> Cancel
                </v-btn>
                <v-btn color="primary darken-1" @click.stop="save">
                    <v-icon class="mr-1">mdi-account</v-icon> Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-card>
</template>
<script>
import { mapActions } from "vuex";

export default {
    name: "rental-address-edit",
    components: {},
    props: {
        data: {
            type: Object,
            required: false,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            n: 0,
            addressData: this.data,
            color: "primary darken-1", // primary color for this control
            profileFields: [
                {
                    type: "text",
                    name: "name",
                    key: "primary_contact.name",
                    label: "Name",
                    icon: this.$config("icons.name"),
                    required: true,
                },
                {
                    type: "select",
                    name: "status",
                    key: "status",
                    label: "Status",
                    icon: this.$config("icons.rental_status"),
                    rules: [(v) => !!v || "Status is required"],
                    required: true,
                    placeholder: "Enter status",
                    items: [
                        {
                            text: "Rent from a 3rd Party",
                            value: "Rent from a 3rd Party",
                        },
                        {
                            text: "Own my home",
                            value: "Own my home",
                        },
                        {
                            text: "Live with Family",
                            value: "Live with Family",
                        },
                        {
                            text: "Live with Friend",
                            value: "Live with Friend",
                        },
                    ],
                },
                {
                    type: "text",
                    name: "label",
                    key: "label",
                    label: "Label",
                    icon: this.$config("icons.rental_label"),
                    required: true,
                },
                {
                    type: "text",
                    name: "phone",
                    key: "primary_contact.phone",
                    label: "Phone",
                    icon: this.$config("icons.phone"),
                    format: "tel",
                    required: true,
                },
                {
                    type: "text",
                    name: "fax",
                    key: "primary_contact.fax",
                    label: "Fax",
                    icon: this.$config("icons.fax"),
                    format: "tel",
                    required: false,
                },
                {
                    type: "text",
                    format: "email",
                    name: "email",
                    key: "primary_contact.email",
                    label: "Email",
                    icon: this.$config("icons.email"),
                    required: true,
                },
            ],
            addressFields: [
                {
                    type: "select",
                    name: "type",
                    key: "type",
                    label: "Type",
                    icon: this.$config("icons.rental_type"),
                    required: true,
                    // TODO: get this list from the backend model
                    items: [
                        {
                            text: "Current",
                            value: "Current",
                        },
                        {
                            text: "Previous",
                            value: "Previous",
                        },
                    ],
                },
                {
                    type: "text",
                    name: "address",
                    key: "primary_contact.address",
                    label: "Address",
                    icon: this.$config("icons.address"),
                    required: true,
                },
                {
                    type: "text",
                    name: "unit",
                    key: "primary_contact.unit",
                    label: "Unit",
                    icon: this.$config("icons.unit"),
                    required: false,
                },
                {
                    type: "text",
                    name: "city",
                    key: "primary_contact.city",
                    label: "City",
                    icon: this.$config("icons.city"),
                    required: true,
                },
                {
                    type: "text",
                    id: this.$uuid.v4(),
                    name: "state",
                    key: "primary_contact.state",
                    label: "State",
                    icon: this.$config("icons.state"),
                    required: true,
                    placeholder: "Enter state",
                    outerIcon: "",
                },
                {
                    type: "text",
                    name: "zip",
                    key: "primary_contact.zip",
                    label: "Zip Code",
                    icon: this.$config("icons.zip"),
                    required: true,
                },
            ],
            metaFields: [
                {
                    type: "date",
                    name: "move_in_date",
                    key: "move_in_date",
                    label: "Move In Date",
                    icon: this.$config("icons.move_in_date"),
                },
                {
                    type: "date",
                    name: "move_out_date",
                    key: "move_out_date",
                    label: "Move Out Date",
                    icon: this.$config("icons.move_out_date"),
                },
                {
                    id: this.$uuid.v4(),
                    type: "currency",
                    key: "monthly_payment",
                    name: "monthly_payment",
                    label: "Monthly Payment",
                    icon: this.$config("icons.monthly_payment"),
                    required: true,
                },
                {
                    type: "select",
                    name: "payment_type",
                    key: "payment_type",
                    label: "Payment Type",
                    icon: this.$config("icons.payment_type"),
                    required: true,
                    items: [
                        {
                            text: "Rent",
                            value: "Rent",
                        },
                        {
                            text: "Mortgage",
                            value: "Mortgage",
                        },
                    ],
                },
            ],
        };
    },
    computed: {},
    methods: {
        ...mapActions("Screens", ["saveCurrentScreenAddress"]),

        save() {
            // save the address to the store
            this.saveCurrentScreenAddress(this.addressData);
            // send a snackbar message
            this.$emit("close");
        },
        update(addressData) {
            this.addressData = addressData;
        },
        mergedFields(fields1, fields2) {
            return fields1.map((field, index) => {
                return index < fields2.length ? [field, fields2[index]] : field;
            });
        },
        groupedFields(fields) {
            // create a group of 2 column meta fields
            let fieldGroup = [];
            for (let i = 0; i < fields.length; i += 2) {
                let items = [];
                items.push(fields[i]);
                if (i + 1 < fields.length) {
                    items.push(fields[i + 1]);
                }
                fieldGroup.push(items);
            }
            return fieldGroup;
        },
    },
    watch: {},
};
</script>

<style scoped>
</style>