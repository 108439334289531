var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _vm.addressData
        ? _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c("psi-form-section", {
                    attrs: {
                      "two-cols": "",
                      value: _vm.addressData,
                      name: "profile-address-data",
                      label: ["Profile", "Address"],
                      fields: _vm.mergedFields(
                        _vm.profileFields,
                        _vm.addressFields
                      ),
                      color: _vm.color
                    },
                    on: { input: _vm.update }
                  }),
                  _c("psi-form-section", {
                    attrs: {
                      "two-cols": "",
                      value: _vm.addressData,
                      name: "additional-data",
                      label: "Additional Information",
                      fields: _vm.groupedFields(_vm.metaFields),
                      color: _vm.color
                    },
                    on: { input: _vm.update }
                  })
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "mr-2" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: { color: "grey lighten-2" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-1" }, [
                        _vm._v("mdi-close")
                      ]),
                      _vm._v(" Cancel ")
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary darken-1" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.save($event)
                        }
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-1" }, [
                        _vm._v("mdi-account")
                      ]),
                      _vm._v(" Save ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }